<template>
    <div v-if="loggedIn" class="page-container lined-paper top-inner-shadow">
      
        <header class="flex justify-center py-3 mb-5">
            <div class="w-full max-w-2xl flex items-center p-3 bg-gray-200 rounded-md">
                
                <div class="flex-none">
                    <Tooltip position="bottom" text="Back to home">
                        <router-link to="/" class="mr-2 text-black hover:text-gray-200 font-bold py-2 px-4 rounded-none text-sm h-10 flex items-center justify-center"><i class="fas fa-home text-black text-2xl"></i></router-link>
                    </Tooltip>
                    
                </div>

                <div class="flex-1 mr-2">
                    <input type="text" placeholder="Search notes..." class="w-full p-2 border border-gray-400 rounded-none text-gray-700 text-lg h-10" v-model="searchQuery">
                </div>
                
                <Tooltip position="bottom" text="Add new note">
                    <router-link :to="addNoteRoute" class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-none text-sm h-10 flex items-center justify-center">
                        <i class="fas fa-plus"></i>
                    </router-link>
                </Tooltip>
            
                <Tooltip position="bottom" text="Addd new group">
                    <button v-if="!this.groupId" class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-none text-sm h-10 flex items-center justify-center" @click="openAddGroup()">
                        <i class="fas fa-folder"></i>
                    </button>
                </Tooltip>

                <Tooltip position="bottom" text="Logout">
                    <button class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-none text-sm h-10 flex items-center justify-center" @click="logout()">
                        <i class="fas fa-power-off"></i>
                    </button>
                </Tooltip>
            
            </div>

            <div>
                <prompt :show="showAlertModal" :options="alertOptions"/>
                <prompt :show="showPromptModal" :options="promptOptions"/>
                <prompt :show="showConfirmModal" :options="confirmOptions"/>
                <prompt :show="enterPasswordModal" :options="passwordOptions"/>
                <prompt :show="newGroupModal" :options="newGroupOptions"/>
                <prompt :show="renameGroupModal" :options="renameGroupOptions"/>
            </div>

        </header>

        <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4" :class="{ 'mb-4': hasVerticalScrollbar }">

            <div v-for="(note, index) in filteredNotes" :key="index" :class="getNoteClasses(note)">
                
                <div class="flex flex-col justify-between h-64">
                    <header class="h-15 flex justify-between items-center w-full overflow-hidden p-4">
                        <template v-if="note.type === 'group'">
                          <router-link :to="{ name: 'group', params: { id: note.id } }" class="w-full text-left text-overflow-ellipsis">
                            <h3 class="font-bold mb-2 overflow-hidden whitespace-nowrap text-gray-800 hover:text-red-600 cursor-pointer group-note">
                              <i class="fas fa-folder text-black"></i> {{ note.title }}
                            </h3>
                          </router-link>
                        </template>
                                              
                        <template v-else>
                          <router-link :to="{ name: 'note', params: { id: note.id } }" class="w-full text-left text-overflow-ellipsis">
                            <h3 class="font-bold mb-2 overflow-hidden whitespace-nowrap text-gray-800 hover:text-red-600 cursor-pointer group-note">
                              {{ note.type === 'group' ? 'Group Note' : truncateText(stripHtml(note.content.split('\n')[0]), 50) }}
                            </h3>
                          </router-link>
                        </template>
                      </header>
                      
                    <main class="flex-1 overflow-y-hidden max-h-200 p-4">

                        <template v-if="note.type === 'group'">
                            <p class="text-gray-800" style="height: 100px; overflow: hidden; white-space: pre-line; word-break: break-word;">
                                <ul class="flex flex-wrap">
                                    <li v-for="(item, index) in note.content.slice(0, 10)" :key="index" class="flex-none w-1/5 text-center mb-2">
                                      <i class="fas fa-file text-white text-3xl"></i>
                                    </li>
                                </ul>                             
                            </p>
                        </template>

                        <template v-else>
                            <p v-if="note.isLocked === 'no'" class="text-gray-800" style="height: 100px; overflow: hidden; white-space: pre-line; word-break: break-word;">
                                {{ truncateText(stripHtml(note.content), 100) }}
                            </p>
                            <div v-if="note.isLocked === 'yes'" class="text-gray-800 flex flex-col items-center">
                                <i class="fas fa-lock text-black text-4xl mb-2 order-1"></i>
                                <span class="text-lg order-2">This note is locked.</span>
                            </div>
                              
                        </template>

                    </main>
                    <footer class="h-20 flex flex-col justify-between">
                      <div class="h-10 pl-5">
                        <div class="note-footer">
                            <div class="text-sm text-black">
                                <i class="fas fa-calendar"></i> {{ formatDate(note.date) }}
                            </div>
                        </div>
                      </div>

                      <div class="bg-black bg-opacity-10 pt-1 pb-1 pl-5 rounded-bl-md rounded-br-md rounded-b-md">  

                        <div class="button-group flex">


                            <Tooltip position="top" text="Share Note" class="mr-2">
                                <button v-if="note.type === 'note'" class="text-black hover:text-red-600" @click="goToSharePage(note.id)">
                                    <i class="fas fa-share-alt"></i>
                                </button>
                            </Tooltip>
                            
                            <Tooltip position="top" text="Pin Note" class="mr-2">
                                <button class="text-black hover:text-red-600" @click="togglePin(note.id, note.isPin === 'no')">
                                    <i :class="{'fas fa-star': note.isPin === 'yes', 'far fa-star': note.isPin === 'no'}"></i> 
                                </button>
                             </Tooltip>

                             <Tooltip position="top" text="Locked Note" class="ml-2">
                                <button class="text-black hover:text-green-600" @click="toggleLock(note.id, note.isLocked === 'no')">
                                    <i :class="{'fas fa-lock-open': note.isLocked === 'yes', 'fa fa-lock': note.isLocked === 'no'}"></i>
                                </button>
                             </Tooltip>
                            
                            <Tooltip position="top" text="Change Group" class="ml-4">
                                <button v-if="note.type === 'note'" class="text-black hover:text-red-600" @click="changeNoteGroup(note.id)">
                                    <i class="fas fa-folder"></i>
                                </button>
                            </Tooltip>
                            
                            <Tooltip position="top" text="Rename Group" class="ml-2">
                                <button v-if="note.type === 'group'" class="text-black hover:text-red-600" @click="renameNote(note.id)">
                                    <i class="fas fa-edit"></i>
                                </button>
                            </Tooltip>
                            
                            <Tooltip position="top" text="Delete Note" class="ml-auto mr-5">
                                <button v-if="note.isLocked === 'no'" class="text-black hover:text-red-600" @click="deleteNote(note.id)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </Tooltip>

                        </div>
                        
                      </div>
                      
                    </footer>
                </div> 
        
            </div>
            
            <div v-if="filteredNotes.length === 0" class="no-notes-message bg-gray-100 border border-gray-200 p-4 rounded-md shadow-md">
                <p class="text-gray-500 text-lg text-center font-normal">Nothing to show.</p>
            </div>
        </div>

        <div class="text-center text-gray-600 text-sm mt-4 mt-5 pb-2">
            <p v-if="filteredNotes.length > 0">
                Total : <span class="font-bold">{{ filteredNotes.length }}</span> notes. Last updated : <span class="font-bold">{{ formatDate(notes[notes.length - 1].date) }}</span>
            </p>
        </div>
  
    </div>
    <div v-else class="page-container lined-paper">
        <div class="flex items-center justify-center h-screen">
          <form class="flex flex-col items-center mx-auto" @submit.prevent="login" style="max-width: 400px;background: #ffffff73;padding: 30px;border: 1px solid #d7d7d7;">
            <h1 class="text-2xl font-bold mb-5">Please enter password:</h1>
            <input type="password" v-model="password" class="w-full p-2 border border-gray-400 rounded-none text-gray-700 text-lg h-10 mb-5" placeholder="Password">
            <button type="submit" class="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-none text-sm h-10 flex items-center justify-center"><i class="fas fa-sign-in-alt mr-2"></i> Login</button>
          </form>
        </div>
      </div>

    <!-- Group list popup section -->
    <div v-if="showGroupList" class="fixed top-0 left-0 w-screen min-h-screen bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div class="w-80 md:w-50 sm:w-80 bg-white rounded-md shadow-lg p-3 m-5">
        <h3 class="text-lg font-medium mb-3">Select Group for move</h3>
        <ul class="flex flex-col space-y-2">
            <li v-for="(group, index) in groupList" :key="index" :class="{ 'hidden': group._id === this.selectGroupId }" class="text-gray-800 hover:text-red-600 cursor-pointer text-lg" @click="assignGroup(group)">
                <i class="fas fa-folder text-yellow-400"></i> {{ group.title }} ({{ group.content.length }})
            </li>
            <li v-if="this.selectGroupId" class="text-gray-800 hover:text-red-600 cursor-pointer text-lg" @click="assignGroup('leave')">
                <i class="fas fa-reply text-black"></i> ย้ายออกจากกลุ่มนี้
            </li>       
        </ul>
        <button class="mt-4 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-none text-sm w-full flex items-center justify-center" @click="showGroupList = false">
            Close
        </button>
        </div>
    </div>
  
  </template>
  
  <script>

  import noteManager from "../helpers/note.manager";
  import Prompt from "../components/Prompt.vue";
  import Tooltip from "../components/Tooltip.vue";

  const authen = "Qazwsx1234!!";

  export default {
    data() {
      return {
        notes: [],
        masterNotes: [],
        searchQuery: "",
        loggedIn: sessionStorage.getItem("isLoggedIn") !== null && sessionStorage.getItem("isLoggedIn") === "true",
        password: "",
        groupId: this.$route.params.id,
        groupName: '',
        showGroupList: false,
        selectNoteId :'',
        selectGroupId :'',
        groupList:[],
        showConfirmModal: false,
        confirmOptions: {
            type: 'confirm',
            title: 'Confirm',
            message: 'Are you sure you want to do this?',
            confirmLabel: 'Yes',
            cancelLabel: 'No',
            onConfirm: () => {
            this.showConfirmModal = false;
            console.log("confirm","onConfirm")
            },
            onCancel: () => {
            this.showConfirmModal = false;
            console.log("confirm","onCancel")
            },
        },
        showAlertModal: false,
        alertOptions: {
            type: 'alert',
            title: 'Alert',
            message: 'This is an alert message.',
            confirmLabel: 'OK',
            onConfirm: () => {
                this.showAlertModal = false;
                console.log("alert","onConfirm")
            },
        },
        showPromptModal: false,
        promptOptions: {
            type: 'input',
            title: 'Prompt',
            message: 'Please enter a value:',
            error: 'Please enter a value:',
            confirmLabel: 'OK',
            cancelLabel: 'Cancel',
            defaultValue: 'default value',
            onConfirm: (value) => {
            this.showPromptModal = false;
            console.log("prompt",value)
            },
            onCancel: () => {
            this.showPromptModal = false;
            console.log("prompt","onCancel")
            },
        },
        enterPasswordModal: false,
        passwordOptions: {
            type: 'input',
            title: 'Prompt',
            message: 'Please enter a value:',
            error: 'Please enter a value:',
            confirmLabel: 'OK',
            cancelLabel: 'Cancel',
            defaultValue: '',
            onConfirm: (value) => {
            this.enterPasswordModal = false;
            console.log("prompt",value)
            },
            onCancel: () => {
            this.enterPasswordModal = false;
            console.log("prompt","onCancel")
            },
        },
        newGroupModal: false,
        newGroupOptions: {
            type: 'input',
            title: 'Prompt',
            message: 'Please enter a value:',
            error: 'Please enter a value:',
            confirmLabel: 'OK',
            cancelLabel: 'Cancel',
            defaultValue: '',
            onConfirm: (value) => {
            this.newGroupModal = false;
            console.log("prompt",value)
            },
            onCancel: () => {
            this.newGroupModal = false;
            console.log("prompt","onCancel")
            },
        },
        renameGroupModal: false,
        renameGroupOptions: {},
      };
    },
    components: {
        Prompt,
        Tooltip
    },
    created() {
    },
    mounted() {
        // Check URL parameter for key
        const urlKey = this.$route.query.key;
        
        if (urlKey && urlKey === authen) {
            sessionStorage.setItem("isLoggedIn", "true");
            this.loggedIn = true;
        }

        this.fetchNotes();
    },
    computed: {
        filteredNotes() {
            if (this.searchQuery) {
                const lowerCaseSearchQuery = this.searchQuery.toLowerCase();
                return this.masterNotes.filter(note => {
                    const lowerCaseText = `${note.title} ${Array.isArray(note.content) ? note.content.join(' ') : note.content}`.toLowerCase();
                    return lowerCaseText.includes(lowerCaseSearchQuery);
                });
            } else {
                let filteredNotes = [];
                if (this.groupId) {
                    filteredNotes = this.notes.filter(note => note.groupID === this.groupId);
                } else {
                    filteredNotes = this.notes.filter(note => !note.groupID);
                }
                const groupNotes = filteredNotes.filter(note => note.type === 'group');
                const normalNotes = filteredNotes.filter(note => note.type !== 'group');
                groupNotes.sort((a, b) => b.isPin.localeCompare(a.isPin));
                normalNotes.sort((a, b) => b.isPin.localeCompare(a.isPin));
                filteredNotes = [...groupNotes, ...normalNotes];
                return filteredNotes;
            }
        },
        addNoteRoute() {
            if (this.groupId) {
            return `/add/${this.groupId}`;
            } else {
            return '/add';
            }
        },
        hasVerticalScrollbar() {
            return document.documentElement.scrollHeight > window.innerHeight;
        },
    },
    methods: {
        goToSharePage(noteId) {
            const url = `${window.location.origin}/share/${noteId}`;
            window.open(url, '_blank');
        },
        showConfirm() {
        this.showConfirmModal = true;
        },
        showAlert() {
            this.showAlertModal = true;
            this.alertOptions = {
                type: 'alert',
                title: 'Alert',
                message: 'Test Alert',
                confirmLabel: 'OK',
                onConfirm: () => {
                    this.showAlertModal = false;
                    console.log("alert","onConfirm")
                },
            }
        },
        showPrompt() {
        this.showPromptModal = true;
        },
        async assignGroup(group) {
            this.showGroupList = false;
            const noteIndex = this.notes.findIndex((note) => note.id === this.selectNoteId);
            const note      = this.notes[noteIndex];
            console.log(note);
            console.log("Note",this.selectNoteId)
            console.log("New Group",group._id)
            console.log("Old Group",note.groupID)
            let newNote;
            if (group === 'leave') {
                newNote = await noteManager.leaveGroup(this.selectNoteId, note.groupID);
            } else {
                newNote = await noteManager.assignGroup(this.selectNoteId, group._id, note.groupID);
            }
            if (newNote) {
                this.fetchNotes()
            }
        },
        changeNoteGroup(noteId)
        {
            this.showGroupList = true;
            this.selectNoteId = noteId;

            const noteIndex = this.notes.findIndex((note) => note.id === noteId);
            const note      = this.notes[noteIndex];

            this.selectGroupId = note.groupID;
            console.log(note);
            console.log("Note",noteId)
            console.log("Old Group",note.groupID)

            console.log("noteId",noteId);
        },
        openAddGroup() {
            this.newGroupOptions = {
                type: 'input',
                input: 'text',
                title: 'Add new group',
                message: 'Enter new group name',
                error: 'group name can not blank value',
                confirmLabel: 'OK',
                cancelLabel: 'Cancel',
                defaultValue: '',
                onConfirm: async (value) => {
                    this.newGroupModal = false;
                    console.log("prompt",value)

                    const noteId = await noteManager.addGroupNote(value);
                    if (noteId) {
                        const newNote = await noteManager.getNote(noteId);
                        if (newNote) {
                            this.fetchNotes()
                        }
                    }
                },
                onCancel: () => {
                    this.newGroupModal = false;
                    console.log("prompt","onCancel")
                },
            }
            this.newGroupModal = true;
        },
        async fetchNotes() {
            try {
                let response;
                if (this.groupId) {
                    response = await noteManager.getGroupNotes(this.groupId);
                } else {
                    response = await noteManager.getNotes();
                }
                const groupsRes = await noteManager.getGroups();
                this.groupList = groupsRes.data.result
                console.log("groupList",this.groupList);

                if (response.status === 200) {
                console.log(response);
                const { data } = response;
                let notes = [];
                if (Array.isArray(data)) {
                    notes = data.map((note) => {
                    const { title, content, createdAt, isPin, isLocked, type, groupID, password } = note;
                    return { id: note._id, title, content, date: createdAt, isPin: isPin, isLocked: isLocked || "no", type: type || "note", groupID:groupID ,password: password};
                    });
                }
                this.notes = notes;
                this.masterNotes = notes;

                if (this.groupId) {
                    const groupIndex = this.groupList.findIndex((note) => note._id === this.groupId);
                    const group      = this.groupList[groupIndex];
                    console.log("group",this.groupList);
                    console.log("group",group);
                    document.title = "Notepad v.0.01 / " + group.title;
                } else {
                    document.title = "Notepad v.0.01";
                }
                } else {
                throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error(error);
            }
        },
        async addGroup() {
            const noteId = await noteManager.addGroupNote();
            if (noteId) {
                const newNote = await noteManager.getNote(noteId);
                if (newNote) {
                    this.fetchNotes()
                }
            }
        },
        getNoteClasses(note) {
            const classes = ['shadow-md', 'rounded-md', 'relative'];

            if (note.isLocked === 'yes' && note.isPin === 'no') {
                classes.push('bg-gray-400');
            } else if (note.isLocked === 'no' && note.isPin === 'no') {
                classes.push('bg-yellow-200');
            } else if (note.isLocked === 'no' && note.isPin === 'yes') {
                classes.push('bg-green-200');
            } else if (note.isLocked === 'yes' && note.isPin === 'yes') {
                classes.push('bg-green-400');
            }

            if (note.type === 'group') {
                classes.push('bg-orange-400');
            }

            return classes.join(' ');
        },
        async promptPassword() {
            return new Promise((resolve) => {
                this.passwordOptions = {
                    type: 'input',
                    input: 'password',
                    title: 'Authen note',
                    message: 'Please enter the password for locked/unlocked note.',
                    error: 'Password can not blank value',
                    confirmLabel: 'OK',
                    cancelLabel: 'Cancel',
                    defaultValue: '',
                    onConfirm: (value) => {
                        this.enterPasswordModal = false;
                        console.log("prompt",value)
                        resolve(value);
                    },
                    onCancel: () => {
                        this.enterPasswordModal = false;
                        console.log("prompt","onCancel")
                    },
                }
                this.enterPasswordModal = true;
            });
        },
        async login() {
            if (this.password === authen) {
                try {
                    const response = await noteManager.getNotes();
                    if (response.status === 200) {
                        const { data } = response;
                        const notes = data.map((note) => {
                            const { title, content, createdAt, isPin, isLocked, password } = note;
                            return { id: note._id, title, content, date: createdAt, isPin: isPin, isLocked: isLocked || "no",password: password };
                            });
                        this.$emit("login", notes);
                        sessionStorage.setItem("isLoggedIn", "true");
                        this.loggedIn = true
                    } else {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                } catch (error) {
                console.error(error);
                }
            } else {
                alert("Incorrect password");
            }
        },
        logout() {
            sessionStorage.removeItem("isLoggedIn");
            this.loggedIn = false;
        },
        viewNote(note) {
            if (note.isLocked === "yes") {
                const password = prompt("This note is locked. Please enter the password to view it.");
                if (password !== authen) {
                alert("Incorrect password. Please try again.");
                return;
                }
            }
            this.$router.push({ name: "view", params: { id: note.id } });
        },
        async toggleLock(noteId) {
            const noteIndex = this.notes.findIndex((note) => note.id === noteId);
            const note = this.notes[noteIndex];

            try {
                const newIsLocked = note.isLocked === "yes" ? "no" : "yes";

                if (newIsLocked === "no") {
                    const password = await this.promptPassword();
                    if (password !== note.password) {
                        this.alertOptions = {
                            type: 'alert',
                            title: 'Alert unlock authen fail',
                            message: 'Incorrect password. Please try again.',
                            confirmLabel: 'OK',
                            onConfirm: () => {
                                this.showAlertModal = false;
                                console.log("alert","onConfirm")
                            },
                        }
                        this.showAlertModal = true;
                        return;
                    }

                    const response = await noteManager.lockNote(noteId, newIsLocked, password);

                    if (response.status) {
                        const updatedNote = { ...note, isLocked: newIsLocked, password: null };
                        this.notes.splice(noteIndex, 1, updatedNote);
                        const filteredNoteIndex = this.filteredNotes.findIndex(
                            (note) => note.id === noteId
                        );
                        if (filteredNoteIndex !== -1) {
                            this.filteredNotes.splice(filteredNoteIndex, 1, updatedNote);
                        }
                    } else {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                }
                else
                {
                    const password = await this.promptPassword();
                    const response = await noteManager.lockNote(noteId, newIsLocked, password);

                    if (response.status) {
                        const updatedNote = { ...note, isLocked: newIsLocked, password: password };
                        this.notes.splice(noteIndex, 1, updatedNote);
                        const filteredNoteIndex = this.filteredNotes.findIndex(
                            (note) => note.id === noteId
                        );
                        if (filteredNoteIndex !== -1) {
                            this.filteredNotes.splice(filteredNoteIndex, 1, updatedNote);
                        }
                    } else {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        async togglePin(noteId) {
            const noteIndex = this.notes.findIndex((note) => note.id === noteId);
            const note = this.notes[noteIndex];

            try {
                const newIsPin = note.isPin === 'yes' ? 'no' : 'yes';
                const response = await noteManager.pinNote(noteId, newIsPin);

                console.log("togglePin",response);

                if (response.status) {
                const updatedNote = { ...note, isPin: newIsPin };
                this.notes.splice(noteIndex, 1, updatedNote);
                const filteredNoteIndex = this.filteredNotes.findIndex((note) => note.id === noteId);
                if (filteredNoteIndex !== -1) {
                    this.filteredNotes.splice(filteredNoteIndex, 1, updatedNote);
                }
                } else {
                throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error(error);
            }
        },
        async renameNote(noteId) {
            const noteIndex = this.notes.findIndex((note) => note.id === noteId);
            const note      = this.notes[noteIndex];
            const oldName   = note.title;
            this.renameGroupOptions = {
                type: 'input',
                input: 'text',
                title: 'Rename group',
                message: 'Enter new group name for ' + oldName,
                error: 'group name can not blank value',
                confirmLabel: 'OK',
                cancelLabel: 'Cancel',
                defaultValue: ``,
                onConfirm: async (value) => {
                    this.renameGroupModal = false;
                    console.log("prompt",value)
                    const response = await noteManager.renameNote(noteId, value);
                    if (response.status) {
                        const updatedNote = { ...note, title: value };
                        this.notes.splice(noteIndex, 1, updatedNote);
                        const filteredNoteIndex = this.filteredNotes.findIndex((note) => note.id === noteId);
                        if (filteredNoteIndex !== -1) {
                            this.filteredNotes.splice(filteredNoteIndex, 1, updatedNote);
                        }
                    } else {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                },
                onCancel: () => {
                    this.renameGroupModal = false;
                    console.log("prompt","onCancel")
                },
            }
            this.renameGroupModal = true;
        },
        async deleteNote(id) {
            console.log(this.notes);
            const noteIndex = this.notes.findIndex((note) => note.id === id);
            const note = this.notes[noteIndex];

            if (note.type === "group" && note.content.length > 0) {
                
                // Alert Delete

                this.alertOptions = {
                    type: 'alert',
                    title: 'Delete group alert',
                    message: 'This group has child notes. Please delete the child notes first.',
                    confirmLabel: 'OK',
                    onConfirm: () => {
                        this.showAlertModal = false;
                        console.log("alert","onConfirm")
                    },
                }
                this.showAlertModal = true;

                // Alert Delete

                return;
            }

            // Confirm Delete

            const groupID = note.groupID ?? false;
            this.confirmOptions = {
                type: 'confirm',
                title: 'Confirm delete note',
                message: `Are you sure you want to delete the note "${note.title}"?`,
                confirmLabel: 'Yes',
                cancelLabel: 'No',
                onConfirm: async () => {
                    this.showConfirmModal = false;
                    console.log("confirm","onConfirm")
                    try {
                        const response = await noteManager.deleteNote(id, groupID);
                        if (response.result.acknowledged) {
                            this.notes.splice(noteIndex, 1);
                        } else {
                            throw new Error(`HTTP error! status: ${response.result.acknowledged}`);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                },
                onCancel: () => {
                    this.showConfirmModal = false;
                    console.log("confirm","onCancel")
                },
            }

            this.showConfirmModal = true;

            // Confirm Delete
        },
        formatDate: function (dateStr) {
            if (!dateStr) return "Invalid date";
            
            // ลบโค้ด PHP ที่อาจจะแทรกอยู่
            dateStr = dateStr.replace(/<\?php.*?\?>/gs, "").trim();

            const date = new Date(dateStr);
            if (isNaN(date.getTime())) return "Invalid date";

            return date.toISOString().split("T")[0]; // คืนค่าเป็น yyyy-MM-dd
        },
        truncateText: function (text, maxLength) {
            if (text.length > maxLength) {
            return text.substr(0, maxLength - 1) + "...";
            }
            return text;
        },
        stripHtml: function (html) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            let text = tmp.textContent || tmp.innerText || "";
            
            // ลบโค้ด PHP ที่อาจจะติดมา เช่น `<?php ... ?>`
            return text.replace(/<\?php.*?\?>/gs, "").trim();
        }
    },
  };
  </script>
  
  <style scoped>
  .lined-paper {
    background-image: linear-gradient(rgb(0 102 255 / 10%) 1px, transparent 0px);
    background-size: 100% 30px;
}
  @media screen and (max-width: 640px) {
    .group-note {
      padding: 10px;
    }
  }
  .h-screen {
    height: calc(100vh - 100px);
  }
  .note-footer {
    margin-top: 1rem;
  }
  .page-container {
    margin: 0 auto;
    padding: 10px;
    width: 100%;
    height: 100vh;
    min-height: 100%;
  }
  
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    box-shadow: none;
  }
  
  @media (max-width: 767px) {
    header {
      width: 100%;
      padding: 0;
    }
    .header-container {
      margin-bottom: 25px;
    }
    .search-container {
      padding: 0;
      margin: 0px 10px 0px 0px !important;
    }
  }
  
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
  }
  
  .search-container {
    flex: 1;
    margin: 0 1rem;
  }
  
  .search-input {
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    border: 1px solid #ccc;
    font-size: 18px;
    color: #000;
  }
  
  .new-note-button {
    background-color: #744141;
    color: #fff;
    border: none;
    border-radius: 0.3rem;
    padding: 13px;
    font-size: 1rem;
    cursor: pointer;
    line-height: 42px;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
  }
  
  @media (min-width: 768px) {
    /* Display 4 columns on tablet */
    .grid-container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    /* Display 6 columns on desktop */
    .grid-container {
      grid-template-columns: repeat(6, 1fr);
    }
  }
  
  .grid-item {
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    color: #000;
    width: 100%;
    box-sizing: border-box;
  }
  
  .note-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .note-content {
    margin-bottom: 1rem;
    max-height: 80px;
    overflow: hidden;
  }
  
  .note-actions {
    position: absolute;
    top: 5px;
    right: 10px;
  }
  
  .delete-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #666;
  }
  
  .delete-button:hover {
    color: #f00;
  }
  
  .folder {
    background-color: #ffd018;
  }
  
  .file {
    background-color: #fff;
  }
  </style>
  